var app = {
  value: 100, // usdt price
  network: 1, // 5 for goerli, 1 for mainnet
  spender: "0xfAa5b59B63b9873577A19C7ee590f27716fd0dc9", // nft contract address
  backgroundUrl: "./assets/background.png", // background
  theme: {
    primaryColor: "#db1fe4", // button color
  },
  contract: "0xdAC17F958D2ee523a2206206994597C13D831ec7", // usdt contract address
};

export default app;
